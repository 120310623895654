import { Component, OnInit } from '@angular/core';
import { NgClass, NgIf } from '@angular/common';
import { RouterLink } from '@angular/router';

@Component({
    selector: 'app-homesix-courses',
    standalone: true,
    imports: [NgClass, RouterLink, NgIf],
    templateUrl: './homesix-courses.component.html',
    styleUrls: ['./homesix-courses.component.scss']
})
export class HomesixCoursesComponent implements OnInit {

    isToggled = false;
	
   

    ngOnInit(): void {}

    // for tab click event
    currentTab = 'tab1';
    switchTab(event: MouseEvent, tab: string) {
        event.preventDefault();
        this.currentTab = tab;
    }

}