<div
    class="courses-area pt-100 pb-70"
    
>
    <div class="container">
        <div class="courses-list-tab">
            <ul class="nav-tabset">
                <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab1'}">
                    <span class="active" (click)="switchTab($event, 'tab1')">Trending Courses</span>
                </li>
                <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab2'}">
                    <span (click)="switchTab($event, 'tab2')">Most Popular Courses</span>
                </li>
                <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab3'}">
                    <span (click)="switchTab($event, 'tab3')">Most Recent Courses</span>
                </li>
            </ul>
            <div class="tabs-container">
                <div class="pane" id="tab1" *ngIf="currentTab === 'tab1'">
                    <div class="row">
                        <div class="col-lg-4 col-md-6">
                            <div class="single-courses-item mb-30">
                                <div class="courses-image">
                                    <a routerLink="/single-courses" class="d-block"><img src="assets/img/courses/courses1.jpg" alt="image"></a>
                                </div>
    
                                <div class="courses-content">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <div class="course-author d-flex align-items-center">
                                            <img src="assets/img/user1.jpg" class="shadow" alt="image">
                                            <span>Steven Smith</span>
                                        </div>
    
                                        <div class="courses-rating">
                                            <div class="review-stars-rated">
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star-half'></i>
                                            </div>
        
                                            <div class="rating-total">
                                                4.5 (2)
                                            </div>
                                        </div>
                                    </div>
    
                                    <h3><a routerLink="/single-courses" class="d-inline-block">Raque Professional IT Expert Certificate Course</a></h3>
                                    <p>Education encompasses both the teaching and learning of knowledge.</p>
                                </div>
    
                                <div class="courses-box-footer">
                                    <ul>
                                        <li class="students-number">
                                            <i class='bx bx-user'></i> 10 students
                                        </li>
    
                                        <li class="courses-lesson">
                                            <i class='bx bx-book-open'></i> 6 lessons
                                        </li>
    
                                        <li class="courses-price">
                                            Free
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
    
                        <div class="col-lg-4 col-md-6">
                            <div class="single-courses-item mb-30">
                                <div class="courses-image">
                                    <a routerLink="/single-courses" class="d-block"><img src="assets/img/courses/courses2.jpg" alt="image"></a>
                                </div>
    
                                <div class="courses-content">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <div class="course-author d-flex align-items-center">
                                            <img src="assets/img/user2.jpg" class="shadow" alt="image">
                                            <span>Sarah Taylor</span>
                                        </div>
    
                                        <div class="courses-rating">
                                            <div class="review-stars-rated">
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star-half'></i>
                                            </div>
        
                                            <div class="rating-total">
                                                4.5 (2)
                                            </div>
                                        </div>
                                    </div>
    
                                    <h3><a routerLink="/single-courses" class="d-inline-block">Certified Graphic Design with Free Project Course</a></h3>
                                    <p>Education encompasses both the teaching and learning of knowledge.</p>
                                </div>
    
                                <div class="courses-box-footer">
                                    <ul>
                                        <li class="students-number">
                                            <i class='bx bx-user'></i> 15 students
                                        </li>
    
                                        <li class="courses-lesson">
                                            <i class='bx bx-book-open'></i> 10 lessons
                                        </li>
    
                                        <li class="courses-price">
                                            $250
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
    
                        <div class="col-lg-4 col-md-6">
                            <div class="single-courses-item mb-30">
                                <div class="courses-image">
                                    <a routerLink="/single-courses" class="d-block"><img src="assets/img/courses/courses3.jpg" alt="image"></a>
                                </div>
    
                                <div class="courses-content">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <div class="course-author d-flex align-items-center">
                                            <img src="assets/img/user3.jpg" class="shadow" alt="image">
                                            <span>Anderson</span>
                                        </div>
    
                                        <div class="courses-rating">
                                            <div class="review-stars-rated">
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bx-star'></i>
                                            </div>
        
                                            <div class="rating-total">
                                                4.0 (1)
                                            </div>
                                        </div>
                                    </div>
    
                                    <h3><a routerLink="/single-courses" class="d-inline-block">Photography Crash Course for Photographer</a></h3>
                                    <p>Education encompasses both the teaching and learning of knowledge.</p>
                                </div>
    
                                <div class="courses-box-footer">
                                    <ul>
                                        <li class="students-number">
                                            <i class='bx bx-user'></i> 5 students
                                        </li>
    
                                        <li class="courses-lesson">
                                            <i class='bx bx-book-open'></i> 5 lessons
                                        </li>
    
                                        <li class="courses-price">
                                            $150
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
    
                        <div class="col-lg-4 col-md-6">
                            <div class="single-courses-item mb-30">
                                <div class="courses-image">
                                    <a routerLink="/single-courses" class="d-block"><img src="assets/img/courses/courses4.jpg" alt="image"></a>
                                </div>
    
                                <div class="courses-content">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <div class="course-author d-flex align-items-center">
                                            <img src="assets/img/user4.jpg" class="shadow" alt="image">
                                            <span>Liam King</span>
                                        </div>
    
                                        <div class="courses-rating">
                                            <div class="review-stars-rated">
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bx-star'></i>
                                            </div>
        
                                            <div class="rating-total">
                                                4.0 (1)
                                            </div>
                                        </div>
                                    </div>
    
                                    <h3><a routerLink="/single-courses" class="d-inline-block">Web Design for Developing Technology with Joy</a></h3>
                                    <p>Education encompasses both the teaching and learning of knowledge.</p>
                                </div>
    
                                <div class="courses-box-footer">
                                    <ul>
                                        <li class="students-number">
                                            <i class='bx bx-user'></i> 10 students
                                        </li>
    
                                        <li class="courses-lesson">
                                            <i class='bx bx-book-open'></i> 6 lessons
                                        </li>
    
                                        <li class="courses-price">
                                            <span>$200</span>
                                            $195
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
    
                        <div class="col-lg-4 col-md-6">
                            <div class="single-courses-item mb-30">
                                <div class="courses-image">
                                    <a routerLink="/single-courses" class="d-block"><img src="assets/img/courses/courses5.jpg" alt="image"></a>
                                </div>
    
                                <div class="courses-content">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <div class="course-author d-flex align-items-center">
                                            <img src="assets/img/user5.jpg" class="shadow" alt="image">
                                            <span>Lina</span>
                                        </div>
    
                                        <div class="courses-rating">
                                            <div class="review-stars-rated">
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star'></i>
                                            </div>
        
                                            <div class="rating-total">
                                                5.0 (1)
                                            </div>
                                        </div>
                                    </div>
    
                                    <h3><a routerLink="/single-courses" class="d-inline-block">Information About UI/UX Design Degree</a></h3>
                                    <p>Education encompasses both the teaching and learning of knowledge.</p>
                                </div>
    
                                <div class="courses-box-footer">
                                    <ul>
                                        <li class="students-number">
                                            <i class='bx bx-user'></i> 5 students
                                        </li>
    
                                        <li class="courses-lesson">
                                            <i class='bx bx-book-open'></i> 5 lessons
                                        </li>
    
                                        <li class="courses-price">
                                            $178
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
    
                        <div class="col-lg-4 col-md-6">
                            <div class="single-courses-item mb-30">
                                <div class="courses-image">
                                    <a routerLink="/single-courses" class="d-block"><img src="assets/img/courses/courses6.jpg" alt="image"></a>
                                </div>
    
                                <div class="courses-content">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <div class="course-author d-flex align-items-center">
                                            <img src="assets/img/user6.jpg" class="shadow" alt="image">
                                            <span>David Warner</span>
                                        </div>
    
                                        <div class="courses-rating">
                                            <div class="review-stars-rated">
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bx-star'></i>
                                            </div>
        
                                            <div class="rating-total">
                                                4.0 (1)
                                            </div>
                                        </div>
                                    </div>
    
                                    <h3><a routerLink="/single-courses" class="d-inline-block">Photography Photo modify and Beautiful</a></h3>
                                    <p>Education encompasses both the teaching and learning of knowledge.</p>
                                </div>
    
                                <div class="courses-box-footer">
                                    <ul>
                                        <li class="students-number">
                                            <i class='bx bx-user'></i> 11 students
                                        </li>
    
                                        <li class="courses-lesson">
                                            <i class='bx bx-book-open'></i> 1 lessons
                                        </li>
    
                                        <li class="courses-price">
                                            $500
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pane" id="tab2" *ngIf="currentTab === 'tab2'">
                    <div class="row">
                        <div class="col-lg-4 col-md-6">
                            <div class="single-courses-item mb-30">
                                <div class="courses-image">
                                    <a routerLink="/single-courses" class="d-block"><img src="assets/img/courses/courses4.jpg" alt="image"></a>
                                </div>
    
                                <div class="courses-content">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <div class="course-author d-flex align-items-center">
                                            <img src="assets/img/user4.jpg" class="shadow" alt="image">
                                            <span>Liam King</span>
                                        </div>
    
                                        <div class="courses-rating">
                                            <div class="review-stars-rated">
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bx-star'></i>
                                            </div>
        
                                            <div class="rating-total">
                                                4.0 (1)
                                            </div>
                                        </div>
                                    </div>
    
                                    <h3><a routerLink="/single-courses" class="d-inline-block">Web Design for Developing Technology with Joy</a></h3>
                                    <p>Education encompasses both the teaching and learning of knowledge.</p>
                                </div>
    
                                <div class="courses-box-footer">
                                    <ul>
                                        <li class="students-number">
                                            <i class='bx bx-user'></i> 10 students
                                        </li>
    
                                        <li class="courses-lesson">
                                            <i class='bx bx-book-open'></i> 6 lessons
                                        </li>
    
                                        <li class="courses-price">
                                            <span>$200</span>
                                            $195
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="single-courses-item mb-30">
                                <div class="courses-image">
                                    <a routerLink="/single-courses" class="d-block"><img src="assets/img/courses/courses5.jpg" alt="image"></a>
                                </div>
    
                                <div class="courses-content">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <div class="course-author d-flex align-items-center">
                                            <img src="assets/img/user5.jpg" class="shadow" alt="image">
                                            <span>Lina</span>
                                        </div>
    
                                        <div class="courses-rating">
                                            <div class="review-stars-rated">
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star'></i>
                                            </div>
        
                                            <div class="rating-total">
                                                5.0 (1)
                                            </div>
                                        </div>
                                    </div>
    
                                    <h3><a routerLink="/single-courses" class="d-inline-block">Information About UI/UX Design Degree</a></h3>
                                    <p>Education encompasses both the teaching and learning of knowledge.</p>
                                </div>
    
                                <div class="courses-box-footer">
                                    <ul>
                                        <li class="students-number">
                                            <i class='bx bx-user'></i> 5 students
                                        </li>
    
                                        <li class="courses-lesson">
                                            <i class='bx bx-book-open'></i> 5 lessons
                                        </li>
    
                                        <li class="courses-price">
                                            $178
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="single-courses-item mb-30">
                                <div class="courses-image">
                                    <a routerLink="/single-courses" class="d-block"><img src="assets/img/courses/courses6.jpg" alt="image"></a>
                                </div>
    
                                <div class="courses-content">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <div class="course-author d-flex align-items-center">
                                            <img src="assets/img/user6.jpg" class="shadow" alt="image">
                                            <span>David Warner</span>
                                        </div>
    
                                        <div class="courses-rating">
                                            <div class="review-stars-rated">
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bx-star'></i>
                                            </div>
        
                                            <div class="rating-total">
                                                4.0 (1)
                                            </div>
                                        </div>
                                    </div>
    
                                    <h3><a routerLink="/single-courses" class="d-inline-block">Photography Photo modify and Beautiful</a></h3>
                                    <p>Education encompasses both the teaching and learning of knowledge.</p>
                                </div>
    
                                <div class="courses-box-footer">
                                    <ul>
                                        <li class="students-number">
                                            <i class='bx bx-user'></i> 11 students
                                        </li>
    
                                        <li class="courses-lesson">
                                            <i class='bx bx-book-open'></i> 1 lessons
                                        </li>
    
                                        <li class="courses-price">
                                            $500
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="single-courses-item mb-30">
                                <div class="courses-image">
                                    <a routerLink="/single-courses" class="d-block"><img src="assets/img/courses/courses1.jpg" alt="image"></a>
                                </div>
    
                                <div class="courses-content">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <div class="course-author d-flex align-items-center">
                                            <img src="assets/img/user1.jpg" class="shadow" alt="image">
                                            <span>Steven Smith</span>
                                        </div>
    
                                        <div class="courses-rating">
                                            <div class="review-stars-rated">
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star-half'></i>
                                            </div>
        
                                            <div class="rating-total">
                                                4.5 (2)
                                            </div>
                                        </div>
                                    </div>
    
                                    <h3><a routerLink="/single-courses" class="d-inline-block">Raque Professional IT Expert Certificate Course</a></h3>
                                    <p>Education encompasses both the teaching and learning of knowledge.</p>
                                </div>
    
                                <div class="courses-box-footer">
                                    <ul>
                                        <li class="students-number">
                                            <i class='bx bx-user'></i> 10 students
                                        </li>
    
                                        <li class="courses-lesson">
                                            <i class='bx bx-book-open'></i> 6 lessons
                                        </li>
    
                                        <li class="courses-price">
                                            Free
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="single-courses-item mb-30">
                                <div class="courses-image">
                                    <a routerLink="/single-courses" class="d-block"><img src="assets/img/courses/courses2.jpg" alt="image"></a>
                                </div>
    
                                <div class="courses-content">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <div class="course-author d-flex align-items-center">
                                            <img src="assets/img/user2.jpg" class="shadow" alt="image">
                                            <span>Sarah Taylor</span>
                                        </div>
    
                                        <div class="courses-rating">
                                            <div class="review-stars-rated">
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star-half'></i>
                                            </div>
        
                                            <div class="rating-total">
                                                4.5 (2)
                                            </div>
                                        </div>
                                    </div>
    
                                    <h3><a routerLink="/single-courses" class="d-inline-block">Certified Graphic Design with Free Project Course</a></h3>
                                    <p>Education encompasses both the teaching and learning of knowledge.</p>
                                </div>
    
                                <div class="courses-box-footer">
                                    <ul>
                                        <li class="students-number">
                                            <i class='bx bx-user'></i> 15 students
                                        </li>
    
                                        <li class="courses-lesson">
                                            <i class='bx bx-book-open'></i> 10 lessons
                                        </li>
    
                                        <li class="courses-price">
                                            $250
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="single-courses-item mb-30">
                                <div class="courses-image">
                                    <a routerLink="/single-courses" class="d-block"><img src="assets/img/courses/courses3.jpg" alt="image"></a>
                                </div>
    
                                <div class="courses-content">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <div class="course-author d-flex align-items-center">
                                            <img src="assets/img/user3.jpg" class="shadow" alt="image">
                                            <span>Anderson</span>
                                        </div>
    
                                        <div class="courses-rating">
                                            <div class="review-stars-rated">
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bx-star'></i>
                                            </div>
        
                                            <div class="rating-total">
                                                4.0 (1)
                                            </div>
                                        </div>
                                    </div>
    
                                    <h3><a routerLink="/single-courses" class="d-inline-block">Photography Crash Course for Photographer</a></h3>
                                    <p>Education encompasses both the teaching and learning of knowledge.</p>
                                </div>
    
                                <div class="courses-box-footer">
                                    <ul>
                                        <li class="students-number">
                                            <i class='bx bx-user'></i> 5 students
                                        </li>
    
                                        <li class="courses-lesson">
                                            <i class='bx bx-book-open'></i> 5 lessons
                                        </li>
    
                                        <li class="courses-price">
                                            $150
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pane" id="tab3" *ngIf="currentTab === 'tab3'">
                    <div class="row">
                        <div class="col-lg-4 col-md-6">
                            <div class="single-courses-item mb-30">
                                <div class="courses-image">
                                    <a routerLink="/single-courses" class="d-block"><img src="assets/img/courses/courses3.jpg" alt="image"></a>
                                </div>
    
                                <div class="courses-content">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <div class="course-author d-flex align-items-center">
                                            <img src="assets/img/user3.jpg" class="shadow" alt="image">
                                            <span>Anderson</span>
                                        </div>
    
                                        <div class="courses-rating">
                                            <div class="review-stars-rated">
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bx-star'></i>
                                            </div>
        
                                            <div class="rating-total">
                                                4.0 (1)
                                            </div>
                                        </div>
                                    </div>
    
                                    <h3><a routerLink="/single-courses" class="d-inline-block">Photography Crash Course for Photographer</a></h3>
                                    <p>Education encompasses both the teaching and learning of knowledge.</p>
                                </div>
    
                                <div class="courses-box-footer">
                                    <ul>
                                        <li class="students-number">
                                            <i class='bx bx-user'></i> 5 students
                                        </li>
    
                                        <li class="courses-lesson">
                                            <i class='bx bx-book-open'></i> 5 lessons
                                        </li>
    
                                        <li class="courses-price">
                                            $150
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="single-courses-item mb-30">
                                <div class="courses-image">
                                    <a routerLink="/single-courses" class="d-block"><img src="assets/img/courses/courses4.jpg" alt="image"></a>
                                </div>
    
                                <div class="courses-content">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <div class="course-author d-flex align-items-center">
                                            <img src="assets/img/user4.jpg" class="shadow" alt="image">
                                            <span>Liam King</span>
                                        </div>
    
                                        <div class="courses-rating">
                                            <div class="review-stars-rated">
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bx-star'></i>
                                            </div>
        
                                            <div class="rating-total">
                                                4.0 (1)
                                            </div>
                                        </div>
                                    </div>
    
                                    <h3><a routerLink="/single-courses" class="d-inline-block">Web Design for Developing Technology with Joy</a></h3>
                                    <p>Education encompasses both the teaching and learning of knowledge.</p>
                                </div>
    
                                <div class="courses-box-footer">
                                    <ul>
                                        <li class="students-number">
                                            <i class='bx bx-user'></i> 10 students
                                        </li>
    
                                        <li class="courses-lesson">
                                            <i class='bx bx-book-open'></i> 6 lessons
                                        </li>
    
                                        <li class="courses-price">
                                            <span>$200</span>
                                            $195
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="single-courses-item mb-30">
                                <div class="courses-image">
                                    <a routerLink="/single-courses" class="d-block"><img src="assets/img/courses/courses1.jpg" alt="image"></a>
                                </div>
    
                                <div class="courses-content">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <div class="course-author d-flex align-items-center">
                                            <img src="assets/img/user1.jpg" class="shadow" alt="image">
                                            <span>Steven Smith</span>
                                        </div>
    
                                        <div class="courses-rating">
                                            <div class="review-stars-rated">
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star-half'></i>
                                            </div>
        
                                            <div class="rating-total">
                                                4.5 (2)
                                            </div>
                                        </div>
                                    </div>
    
                                    <h3><a routerLink="/single-courses" class="d-inline-block">Raque Professional IT Expert Certificate Course</a></h3>
                                    <p>Education encompasses both the teaching and learning of knowledge.</p>
                                </div>
    
                                <div class="courses-box-footer">
                                    <ul>
                                        <li class="students-number">
                                            <i class='bx bx-user'></i> 10 students
                                        </li>
    
                                        <li class="courses-lesson">
                                            <i class='bx bx-book-open'></i> 6 lessons
                                        </li>
    
                                        <li class="courses-price">
                                            Free
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="single-courses-item mb-30">
                                <div class="courses-image">
                                    <a routerLink="/single-courses" class="d-block"><img src="assets/img/courses/courses2.jpg" alt="image"></a>
                                </div>
    
                                <div class="courses-content">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <div class="course-author d-flex align-items-center">
                                            <img src="assets/img/user2.jpg" class="shadow" alt="image">
                                            <span>Sarah Taylor</span>
                                        </div>
    
                                        <div class="courses-rating">
                                            <div class="review-stars-rated">
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star-half'></i>
                                            </div>
        
                                            <div class="rating-total">
                                                4.5 (2)
                                            </div>
                                        </div>
                                    </div>
    
                                    <h3><a routerLink="/single-courses" class="d-inline-block">Certified Graphic Design with Free Project Course</a></h3>
                                    <p>Education encompasses both the teaching and learning of knowledge.</p>
                                </div>
    
                                <div class="courses-box-footer">
                                    <ul>
                                        <li class="students-number">
                                            <i class='bx bx-user'></i> 15 students
                                        </li>
    
                                        <li class="courses-lesson">
                                            <i class='bx bx-book-open'></i> 10 lessons
                                        </li>
    
                                        <li class="courses-price">
                                            $250
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="single-courses-item mb-30">
                                <div class="courses-image">
                                    <a routerLink="/single-courses" class="d-block"><img src="assets/img/courses/courses5.jpg" alt="image"></a>
                                </div>
    
                                <div class="courses-content">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <div class="course-author d-flex align-items-center">
                                            <img src="assets/img/user5.jpg" class="shadow" alt="image">
                                            <span>Lina</span>
                                        </div>
    
                                        <div class="courses-rating">
                                            <div class="review-stars-rated">
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star'></i>
                                            </div>
        
                                            <div class="rating-total">
                                                5.0 (1)
                                            </div>
                                        </div>
                                    </div>
    
                                    <h3><a routerLink="/single-courses" class="d-inline-block">Information About UI/UX Design Degree</a></h3>
                                    <p>Education encompasses both the teaching and learning of knowledge.</p>
                                </div>
    
                                <div class="courses-box-footer">
                                    <ul>
                                        <li class="students-number">
                                            <i class='bx bx-user'></i> 5 students
                                        </li>
    
                                        <li class="courses-lesson">
                                            <i class='bx bx-book-open'></i> 5 lessons
                                        </li>
    
                                        <li class="courses-price">
                                            $178
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="single-courses-item mb-30">
                                <div class="courses-image">
                                    <a routerLink="/single-courses" class="d-block"><img src="assets/img/courses/courses6.jpg" alt="image"></a>
                                </div>
    
                                <div class="courses-content">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <div class="course-author d-flex align-items-center">
                                            <img src="assets/img/user6.jpg" class="shadow" alt="image">
                                            <span>David Warner</span>
                                        </div>
    
                                        <div class="courses-rating">
                                            <div class="review-stars-rated">
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bx-star'></i>
                                            </div>
        
                                            <div class="rating-total">
                                                4.0 (1)
                                            </div>
                                        </div>
                                    </div>
    
                                    <h3><a routerLink="/single-courses" class="d-inline-block">Photography Photo modify and Beautiful</a></h3>
                                    <p>Education encompasses both the teaching and learning of knowledge.</p>
                                </div>
    
                                <div class="courses-box-footer">
                                    <ul>
                                        <li class="students-number">
                                            <i class='bx bx-user'></i> 11 students
                                        </li>
    
                                        <li class="courses-lesson">
                                            <i class='bx bx-book-open'></i> 1 lessons
                                        </li>
    
                                        <li class="courses-price">
                                            $500
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>