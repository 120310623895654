
<app-header-style-four></app-header-style-four>


<app-homesix-main-banner></app-homesix-main-banner>

<div class="courses-categories-area bg-F7F9FB pt-100 pb-70">
    <div class="container">
        <div class="section-title text-start">
            <span class="sub-title">Courses Categories</span>
            <h2>Browse Trending Categories</h2>
            <a routerLink="/courses-category-style-3" class="default-btn"><i class='bx bx-show-alt icon-arrow before'></i><span class="label">View All</span><i class="bx bx-show-alt icon-arrow after"></i></a>
        </div>
    </div>
</div>

<app-homesix-courses></app-homesix-courses>


<div class="funfacts-style-two ptb-70">
    <div class="container">
    </div>
</div>



