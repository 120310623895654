import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  
  private readonly loadingSubject$ = new BehaviorSubject<boolean>(true);

  get loading$(): Observable<boolean> {
    return this.loadingSubject$.asObservable();
  }

  show() {
    this.loadingSubject$.next(true);
  }

  hide() {
    this.loadingSubject$.next(false);
  }
}