import { Component, OnInit, HostListener } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { NgClass } from '@angular/common';

@Component({
    selector: 'app-header-style-four',
    standalone: true,
    imports: [RouterLinkActive, NgClass, RouterLink],
    templateUrl: './header-style-four.component.html',
    styleUrls: ['./header-style-four.component.scss']
})
export class HeaderStyleFourComponent implements OnInit {

    itemLink = 0

    displayMenu(itemNumber: number){
        if(itemNumber == this.itemLink){
            this.itemLink = 0
            return
        }
        this.itemLink = itemNumber
    }

    noneMenu(){
        console.log("Okkkkk");
        
        this.itemLink = 0
        return
    }

    isSticky: boolean = false;
    @HostListener('window:scroll', ['$event'])
    checkScroll() {
        const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
        if (scrollPosition >= 50) {
            this.isSticky = true;
        } else {
            this.isSticky = false;
        }
    }

    @HostListener('document:click', ['$event'])
    onClickOutside(event: MouseEvent) {
        console.log("itemLiknk", this.itemLink);
        
        const menuLinkDiv = document.getElementById('menulink');
        if (menuLinkDiv && (this.itemLink !== 0) && !this.isChildOfMenuLinkDiv(event.target as HTMLElement)) {
            this.itemLink = 0;
        }
    }

    isChildOfMenuLinkDiv(element: HTMLElement): boolean {
        if (!element || element === document.body) {
            return false;
        }
        if (element.className.includes('menulink')) {
            return true;
        }
        return this.isChildOfMenuLinkDiv(element.parentElement);
    }

    

    
    ngOnInit(): void {}

    classApplied = false;
    toggleClass() {
        this.classApplied = !this.classApplied;
    }

    classApplied2 = false;
    toggleClass2() {
        this.classApplied2 = !this.classApplied2;
    }

    classApplied3 = false;
    toggleClass3() {
        this.classApplied3 = !this.classApplied3;
    }

}