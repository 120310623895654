
import { Component, OnInit } from '@angular/core';
import { LoaderService } from '../../services/loader/loader.service';
import { Observable, of } from 'rxjs';
import { CommonModule } from '@angular/common';
import { LoaderInterceptor } from '../../interceptors/loader/loader.interceptor';

@Component({
  selector: 'app-loader',
  standalone: true,
  providers: [LoaderService, LoaderInterceptor],
  imports: [CommonModule],
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {
  isLoading$: Observable<boolean> = of(false);

  constructor(private readonly loaderService: LoaderService) {
  }


  ngOnInit() {
    this.isLoading$ = this.loaderService.loading$
  }
}