import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomePageSixComponent } from './features/home-page-six/home-page-six.component';
import { LoginComponent } from './features/auth/pages/login/login.component';
import { RegisterComponent } from './features/auth/pages/register/register.component';
import { ErrorPageComponent } from './shared/components/error-page/error-page.component';


export const routes: Routes = [
    { path: '', component: HomePageSixComponent },
    {
        path: 'site',
        loadChildren: () => import('./features/site/routes').then(m => m.siteRoutes)
    },
    {
        path: 'auth',
        loadChildren: () => import('./features/auth/routes').then(m => m.authRoutes)
    },
    {path: 'login', component: LoginComponent},
    {path: 'register', component: RegisterComponent},




    // 
    // Here add new pages component

    { path: '**', component: ErrorPageComponent } // This line will remain down from the whole pages component list
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule]
})
export class AppRoutingModule { }