import { Component, OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';
import { HeaderStyleFourComponent } from '../../../shared/components/header-style-four/header-style-four.component';

@Component({
    selector: 'app-error-page',
    standalone: true,
    imports: [HeaderStyleFourComponent, RouterLink],
    templateUrl: './error-page.component.html',
    styleUrls: ['./error-page.component.scss']
})
export class ErrorPageComponent implements OnInit {

    isToggled = false;
	
   

    ngOnInit(): void {}

}