import { AsyncPipe, Location, LocationStrategy, NgIf, PathLocationStrategy } from '@angular/common';
import { Router, NavigationCancel, NavigationEnd, RouterOutlet, NavigationStart, NavigationError } from '@angular/router';
import { Component } from '@angular/core';
import { filter } from 'rxjs/operators';
import { LoaderComponent } from "./shared/components/loader/loader.component";
import { LoaderService } from './shared/services/loader/loader.service';
import { Observable, of } from 'rxjs';
import { InstitutionsService } from './features/site/institutions/services/institutions.service';
import { HttpClientModule } from '@angular/common/http';
import { TrainingService } from './features/site/training/services/training.service';
declare let $: any;

@Component({
    selector: 'app-root',
    standalone: true,
    imports: [ HttpClientModule, RouterOutlet, AsyncPipe, NgIf, LoaderComponent],
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [ 
        InstitutionsService,
        TrainingService,
        LoaderService,
        Location, {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }
    ]
})
export class AppComponent {
    
    title: any;
    location: any;
    isToggled = false;
    routerSubscription: any;

    isLoading$: Observable<boolean> = of(false);


    constructor(private readonly router: Router, private readonly loaderService: LoaderService) {
        this.router.events.subscribe(event => {
          if (event instanceof NavigationStart) {
            this.loaderService.show();
          } else if (event instanceof NavigationEnd || event instanceof NavigationCancel || event instanceof NavigationError) {
            this.loaderService.hide();
          }
        });
      }


      

    ngOnInit(){
        this.recallJsFuntions();
        this.isLoading$ = this.loaderService.loading$
    }

    recallJsFuntions() {
        this.routerSubscription = this.router.events
        .pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel))
        .subscribe(event => {
            this.location = this.router.url;
            if (!(event instanceof NavigationEnd)) {
                return;
            }
            window.scrollTo(0, 0);
        });
    }
    
}