import { HttpClient, HttpParams } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { Observable, tap, catchError, BehaviorSubject, map, concatMap, throwError, of } from "rxjs";
import { environment } from "src/environments/environment";
import { Category, IInstitutionGet, IInstitutionGetItem, IResInstitutionGet, } from "../interfaces/institution-get.interface";
import { IInstitutionDetailGet, IInstitutionDetailItemGet, IResInstitutionDetailGet, } from "../interfaces/institution-get-detail.interface";
import { IFilter } from "../interfaces/filter.interface";
import { IGrade } from "../interfaces/grade.interface";
import { IDomainGet } from "../../domains/interfaces/domain-get.interface";
import { ICityGet } from "../../common/interfaces/city-get.interface";
import { ICategoryGetItem } from "../../common/interfaces/category-get.interface";

@Injectable({
    providedIn: 'root',
})
export class InstitutionsService {

    private readonly _institutions$ = new BehaviorSubject<IResInstitutionGet>(null);
    get institutions$(): Observable<IResInstitutionGet> {
        return this._institutions$.asObservable();
    }

    private readonly _allInstitutions$ = new BehaviorSubject<IInstitutionGet[]>([]);
    private _allFilterInstitutions: IInstitutionGet[];
    get allInstitution$(): Observable<IInstitutionGet[]> {
        return this._allInstitutions$.asObservable();
    }


    filterCheck: IFilter
    private lastInstitutionsLoad = 0;
    private pageCurrent = 0;

    private totaItems = 0;

    private readonly apiURL = environment.baseUrl;
    private readonly _http = inject(HttpClient)

    /**
      * Retrieve paginate list of institution   
      * 
      * @param {number, number}
      * @returns {Observable<IGradeGet[]>}
      */
    public getAllInstitution(page: number = this.pageCurrent, limit: number = 15): Observable<IResInstitutionGet> {

        this.pageCurrent = page

        const params = new HttpParams().set("page", page.toString()).set("limit", limit.toString());
        return this._http.get<IResInstitutionGet>(`${this.apiURL}/institution/get-all`, { params }).pipe(
            tap((res) => {
                this.totaItems = res.totalItems;
                console.log("First: ", res);
            }),
            concatMap((res) => {
                console.log("time: ", this.lastInstitutionsLoad);

                if (Date.now() - this.lastInstitutionsLoad <= 300000) {
                    this._allInstitutions$.subscribe(res => {
                        this._allFilterInstitutions = res
                    })
                    return of(res);
                } else if (this.totaItems > limit) {
                    // Make a second call to retrieve all establishments
                    return this._http.get<IResInstitutionGet>(`${this.apiURL}/institution/get-all?limit=${this.totaItems}`).pipe(
                        tap((resp) => {
                            this.lastInstitutionsLoad = Date.now();
                            console.log("Last: ", resp);
                            console.log("time: ", this.lastInstitutionsLoad);
                            this._institutions$.next(resp)
                            this._allInstitutions$.next(resp.data);
                            this._allFilterInstitutions = resp.data;
                        }),
                        // Return the original response from the first query
                        map(() => res)
                    );
                }
            }),
            catchError((error) => {
                console.error("Erreur lors de la récupération des établissements :", error);
                return throwError(error);
            })
        );
    }

  /**
  * Retrieve alll list of institution   
  * 
  * @param {number}
  * @returns {Observable<IResInstitutionGet>}
  */
    public fetchAllInstitution(limit: number = 10000): Observable<IResInstitutionGet> {
        
        if (Date.now() - this.lastInstitutionsLoad <= 300000) {
           
            return this.institutions$;

        } else {
            this.totaItems = limit
            return this._http.get<IResInstitutionGet>(`${this.apiURL}/institution/get-all?limit=${this.totaItems}`).pipe(
                tap((response) => {
                    this.lastInstitutionsLoad = Date.now();
                    console.log("time: ", this.lastInstitutionsLoad);
                    this._institutions$.next(response)
                    this._allInstitutions$.next(response.data);
                    this._allFilterInstitutions = response.data;
                }),
            );
        }
    }

    /**
     * Search the institutions by keyword
     *
     *  @param {string}
     * @returns {IInstitutionGet[]; ICityGet[] }
     */
    public searchInstitution(word: string): { filterInstitutions: IInstitutionGet[]; filterCity: ICityGet[] } {
        let filterInstitutions: IInstitutionGet[] = [];
        let filterCity: ICityGet[] = [];

        this.allInstitution$.subscribe((res) => {
            res.forEach((item) => {
                const normalizedName = item?.institution?.name
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                    .toUpperCase()
                    .trim();
                const city = item?.institution?.city?.name
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                    .toUpperCase()
                    .trim();
                const normalizedWord = word
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                    .toUpperCase()
                    .trim();
                if (
                    normalizedName.includes(normalizedWord) ||
                    city?.includes(normalizedWord)
                ) {
                    if (city?.includes(normalizedWord)) {
                        const existCity = filterCity.some(
                            (resp) => resp.id === item?.institution?.city?.id
                        );
                        if (filterCity.length > 0 && !existCity) {
                            filterCity.push(item?.institution?.city);
                        } else if (filterCity.length == 0) {
                            filterCity.push(item?.institution?.city);
                        }
                    } else {
                        console.log("");
                    }

                    filterInstitutions.push(item);
                }
            });
        });

        return { filterInstitutions, filterCity };
    }

    /**
    * Retrieve one institution by ID
    *
    * @param {string}
    * @returns { Observable<IInstitutionDetailGet> }
    */
    public getOneInstitution(idIdinstitution: string): Observable<IInstitutionDetailGet> {
        return this._http
            .get<IResInstitutionDetailGet>(
                `${this.apiURL}/institution/get-one/${idIdinstitution}`
            )
            .pipe(
                map((response) => {
                    return response.data;
                }),
                catchError((error) => {
                    console.error(
                        "Erreur lors de la récupération de l'Institutions :",
                        error
                    );
                    throw error;
                })
            );
    }

    /**
     * Retrieve institutions by Filter ID
     *
     * @param {string}
     * @returns { Observable<IInstitutionDetailGet> }
     */
    public getAllInstitutionMultiFilters(ids: string[], page: number = 0, limit: number = 15, filterCheck?: IFilter): Observable<IResInstitutionGet> {
        this.filterCheck = filterCheck
        const params = new HttpParams().set("page", page).set("limit", limit);
        return this._http.get<IResInstitutionGet>(`${this.apiURL}/institution/multi-search?ids=${ids}`, { params }).pipe(
            tap((res) => {
                this.totaItems = res.totalItems;
                console.log("First 1: ", res);
            }),
            concatMap((res) => {
                return this._http.get<IResInstitutionGet>(`${this.apiURL}/institution/multi-search?ids=${ids}&limit=${this.totaItems}`).pipe(
                    tap(resp => {
                        console.log("Last 1", resp);
                        this._allFilterInstitutions = resp.data
                    }),
                    // Retourner la réponse originale de la première requête
                    map(() => res)
                )
            }),
            catchError((error) => {
                console.error(
                    "Erreur lors de la récupération des Institutions :",
                    error
                );
                throw error;
            })
        );
    }

    /**
     * Update one institution
     *
     * @param {any, string}
     * @returns { Observable<IInstitutionDetailGet> }
     */
    public updateInstitution(data: any, idInstitution: string): Observable<IInstitutionDetailItemGet> {
        return this._http
            .put<any>(`${this.apiURL}/institution/update/${idInstitution}`, data)
            .pipe(
                map((response) => {
                    return response.data;
                }),
                catchError((error) => {
                    console.error(
                        "Erreur lors de la mise à jour de l'Institution : ", error
                    );
                    throw error;
                })
            );
    }

    /**
     * Sort filter items 
     *
     * @param {IInstitutionGet[]}
     * @returns { ICityGet[]; IDomainGet[]; Category[]; any[] }
     */
    public filterElements(institutions: IInstitutionGet[]): { filterCities: ICityGet[]; filterDomaines: IDomainGet[]; filterTypes: ICategoryGetItem[]; filterStatuts: any[] } {
        let filterCities: ICityGet[] = [];
        let filterDomaines: IDomainGet[] = [];
        let filterTypes: ICategoryGetItem[] = [];
        let filterStatuts: any[] = [];

        // Utiliser un abonnement pour récupérer les institutions
        // this._allFilterInstitutions.subscribe(institutions => {
        console.log("Institutions filter: ", this._allFilterInstitutions);
        this.allInstitution$.subscribe(res => {

        })
        console.log("Institutions all filter: ", this._allFilterInstitutions);

        this._allFilterInstitutions?.forEach((item) => {
            const city = item?.institution?.city;
            const category = item?.institution?.category;
            const statut = item?.institution?.type;
            const domains = item?.institution?.domains || [];

            // Filtrage des villes
            if (city && !filterCities.some(resp => resp.id === city.id)) {
                filterCities.push(city);
            }

            // Filtrage des catégories
            if (category && !filterTypes.some(resp => resp.id === category.id)) {
                filterTypes.push(category);
            }

            // Filtrage des statuts
            if (statut && !filterStatuts.some(resp => resp.id === statut.id)) {
                filterStatuts.push(statut);
            }

            // Filtrage des domaines
            domains.forEach(domain => {
                if (!filterDomaines.some(resp => resp.id === domain.id)) {
                    filterDomaines.push(domain);
                }
                // });
            });
        });

        return { filterCities, filterDomaines, filterTypes, filterStatuts };
    }

    /**
     * Retrieve the list of grades in an establishment list
     *
     * @param {IInstitutionDetailGet}
     * @returns { IGrade[] }
     */
    public getGradeTraninigByInstitution(institution: IInstitutionDetailGet): { grades: IGrade[]; } {
        let allGrade: IGrade[] = [];

        // Utiliser un abonnement pour récupérer les institutions
        // this._allFilterInstitutions.subscribe(institutions => {
        console.log("educationalPrograms : ", institution?.institution?.educationalPrograms);

        institution.institution.educationalPrograms?.forEach((item) => {
            const grades = item.obtainedGrades || [];
            // Filtrage des domaines
            grades.forEach(grade => {
                if (!allGrade.some(resp => resp.id === grade.id)) {
                    allGrade.push(grade);
                }
                // });
            });
        });

        return { grades: allGrade };
    }

    /**
     * Filter schools of an institution
     *
     * @param {IInstitutionDetailGet}
     * @returns { IGrade[] }
     */
    public filterSchoolByInstitution(childrenInstitutions: Partial<IInstitutionGetItem>[]) {
        const idsInstitutions: string[] = childrenInstitutions.map(child => child.id);
        let matchInstitution: IInstitutionGet[] = []
        let resultInstitutions: { faculties: IInstitutionGetItem[], schoools: IInstitutionGetItem[] } = { faculties: [], schoools: [] }
        return this.institutions$.pipe(
            map(response => {
                console.log("Childrens ser : ", response, this.lastInstitutionsLoad);
                // Vérifiez que response.data est défini et est un tableau
                if (!response || !Array.isArray(response)) {
                    matchInstitution = []; // Ou gérer l'erreur comme vous le souhaitez
                }
                matchInstitution = response.data.filter(institution =>
                    idsInstitutions.includes(institution?.institution?.id)
                );
                const searchCategory = "facul"
                matchInstitution?.forEach(institution => {
                    if (institution?.institution?.category?.name.toLowerCase().includes(searchCategory)) {
                        resultInstitutions?.faculties.push(institution?.institution)
                    } else {
                        resultInstitutions?.schoools.push(institution?.institution)
                    }
                })
                return resultInstitutions
            })
        );
    }
}
