import { Component, OnInit } from '@angular/core';
import { HomesixCoursesComponent } from './homesix-courses/homesix-courses.component';
import { HomesixMainBannerComponent } from './homesix-main-banner/homesix-main-banner.component';
import { RouterLink } from '@angular/router';
import { HeaderStyleFourComponent } from "../../shared/components/header-style-four/header-style-four.component";

@Component({
  selector: 'app-home-page-six',
  standalone: true,
  imports: [HomesixCoursesComponent, HomesixMainBannerComponent, RouterLink, HeaderStyleFourComponent],
  templateUrl: './home-page-six.component.html',
  styleUrls: ['./home-page-six.component.scss']
})
export class HomePageSixComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
